<template>
  <div>
    <HeaderPanel
      title="Plugins"
      :filter="filter"
      placeholder=""
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-container>
        <b-row>
          <b-col
            lg="4"
            md="6"
            cols="12"
            v-for="list of data"
            :key="'card-' + list.id"
          >
            <b-card class="rounded-xl" no-body style="overflow: hidden">
              <div class="setting-icon">
                <b-dropdown
                  dropleft
                  right
                  center
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <font-awesome-icon
                      icon="ellipsis-v"
                      class="color-primary"
                    />
                  </template>
                  <b-dropdown-item
                    @click="$router.push('/setting/plugins/' + list.id)"
                    >Info</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="$router.push('/setting/plugins/logs/' + list.id)"
                    >Logs</b-dropdown-item
                  >
                  <b-dropdown-item @click="openModal(list)"
                    >Setting</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div class="p-3">
                <b-img
                  class="image-logo"
                  :src="list.image_url || default_image"
                  @error="handleImageSrc"
                ></b-img>
                <div class="font-bold mt-2">{{ list.name }}</div>
                <p class="two-lines my-1">
                  {{ list.description }}
                </p>
              </div>
              <template #footer>
                <b-form-checkbox
                  switch
                  class="radio-active"
                  size="md"
                  v-model="list.status"
                  :value="1"
                  @change="updateData(false, list)"
                  :unchecked-value="0"
                >
                  <span class="ml-2 main-label">{{
                    list.status == 1 ? "Active" : "Inactive"
                  }}</span>
                </b-form-checkbox>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <b-modal v-model="modalShow" title="SETTING" @hidden="clearForm">
        <template v-if="form.is_zort">
          <InputText
            textFloat="URL"
            placeholder="URL"
            type="text"
            name="url"
            v-model="form.url"
            :v="$v.form.url"
            :isValidate="$v.form.url.$error"
            isRequired
          />
          <InputText
            textFloat="name"
            placeholder="name"
            type="text"
            name="plugins-name"
            v-model="form.name"
            :v="$v.form.name"
            :isValidate="$v.form.name.$error"
            isRequired
          />
          <InputText
            textFloat="Store Name"
            placeholder="Store Name"
            type="text"
            name="plugins-Store Name"
            v-model="form.storename"
            :v="$v.form.storename"
            :isValidate="$v.form.storename.$error"
            isRequired
          />
          <InputText
            textFloat="API Key"
            placeholder="API Key"
            type="text"
            name="api_key"
            v-model="form.apikey"
            :v="$v.form.apikey"
            :isValidate="$v.form.apikey.$error"
            isRequired
          />
          <InputText
            textFloat="API Secret"
            placeholder="API Secret"
            type="text"
            name="api_key"
            v-model="form.apisecret"
            :v="$v.form.apisecret"
            :isValidate="$v.form.apisecret.$error"
            isRequired
          />
          <InputSelect
            title="Order Status"
            name="Order Status"
            :options="orderStatus"
            v-model="form.order_status"
            valueField="id"
            textField="name"
          >
            <!-- :v="$v.form.order_status"
            :isValidate="$v.form.order_status.$error" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >--- Select order status ---
              </b-form-select-option>
            </template>
          </InputSelect>
          <InputSelect
            title="Order Payment Status"
            name="Order Payment Status"
            :options="paymentStatus"
            v-model="form.order_paymentstatus"
            valueField="id"
            textField="name"
          >
            <!-- :v="$v.form.order_paymentstatus"
            :isValidate="$v.form.order_paymentstatus.$error" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >--- Select payment status ---
              </b-form-select-option>
            </template>
          </InputSelect>
        </template>
        <template v-else>
          <InputText
            textFloat="URL"
            placeholder="URL"
            type="text"
            name="url"
            v-model="form.url"
            :v="$v.form.url"
            :isValidate="$v.form.url.$error"
            isRequired
          />
          <InputText
            textFloat="Username"
            placeholder="Username"
            type="text"
            autocomplete="a"
            name="plugins-username"
            v-model="form.username"
            :v="$v.form.username"
            v-if="form.id != 3"
            :isValidate="$v.form.username.$error"
            isRequired
          />
          <InputText
            textFloat="Password"
            placeholder="Password"
            type="password"
            name="plugins-password"
            v-model="form.password"
            :v="$v.form.password"
            :isValidate="$v.form.password.$error"
            isRequired
            v-if="form.id != 3"
          />
          <InputText
            textFloat="Token"
            placeholder="Token"
            type="text"
            name="token"
            v-model="form.token"
            :v="$v.form.token"
            :isValidate="$v.form.token.$error"
            isRequired
            v-if="form.id == 3"
          />
          <InputText
            textFloat="API Key"
            placeholder="API Key"
            type="text"
            name="api_key"
            v-model="form.apikey"
            :v="$v.form.apikey"
            :isValidate="$v.form.apikey.$error"
            isRequired
            v-if="form.id != 3"
          />
          <InputText
            textFloat="Brand Code"
            placeholder="Brand Code"
            type="text"
            name="brand_code"
            v-model="form.brand_code"
            :v="$v.form.brand_code"
            :isValidate="$v.form.brand_code.$error"
            isRequired
          />

          <InputText
            textFloat="Channel ID"
            placeholder="Channel ID"
            type="text"
            name="channel_id"
            v-model="form.chanel_id"
            :v="$v.form.chanel_id"
            :isValidate="$v.form.chanel_id.$error"
            isRequired
            v-if="form.id != 3"
          />
          <InputText
            textFloat="Mock Email (if Email is Empty)"
            placeholder="Email"
            type="text"
            name="email-mock"
            v-model="form.mock_email"
            :v="$v.form.mock_email"
            :isValidate="$v.form.mock_email.$error"
            isRequired
            v-if="form.id != 3"
          />
          <div v-if="form.id != 3">
            <label class="font-weight-bold text-black">
              Mock Birthday (if Birthday is Empty)
              <span class="text-danger">*</span>
            </label>
            <div
              :class="[
                'input-container',
                { error: $v.form.mock_birthday.$error },
              ]"
            >
              <datetime
                type="date"
                v-model="form.mock_birthday"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                ref="birthdate"
                :isValidate="$v.form.mock_birthday.$error"
                :v="$v.form.mock_birthday"
              >
              </datetime>
              <div
                :class="'icon-primary text-right cursor-default'"
                @click="$refs.birthdate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="'pointer color-primary cursor-default color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div v-if="$v.form.mock_birthday.$error && form.id != 3">
            <span
              class="text-error"
              v-if="$v.form.mock_birthday.required == false"
              >Please select date.</span
            >
          </div>
        </template>
        <template #modal-footer>
          <b-row class="w-100 justify-content-between" no-gutters>
            <b-col cols="4">
              <b-button
                block
                @click.prevent="modalShow = false"
                class="border-btn"
                >Cancel</b-button
              >
            </b-col>

            <b-col cols="4">
              <b-button
                block
                class="submit-btn"
                @click.prevent="updateData(true)"
                >Confirm</b-button
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { required, email, requiredIf } from "vuelidate/lib/validators";
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};
export default {
  data() {
    return {
      filter: {},
      data: [],
      modalShow: false,
      form: {
        id: 0,
        name: "",
        image_url: null,
        description: null,
        status: 1,
        url: "",
        username: "",
        password: "",
        brand_code: "",
        chanel_id: "",
        mock_email: "",
        mock_birthday: "",
        token: null,
        apisecret: null,
        apikey: null,
        storename: null,
        order_status: null,
        order_paymentstatus: null,
      },
      orderStatus: [
        { name: "Pending", id: "Pending" },
        { name: "Success", id: "Success" },
        { name: "Voided", id: "Voided" },
        { name: "Waiting", id: "Waiting" },
        { name: "ไม่ใส่", id: "" },
      ],
      paymentStatus: [
        { name: "Pending", id: "Pending" },
        { name: "Paid", id: "Paid" },
        { name: "Voided", id: "Voided" },
        { name: "Waiting", id: "Waiting" },
        { name: "Partial", id: "Partial" },
        { name: "Overpaid", id: "Overpaid" },
        { name: "ไม่ใส่", id: "" },
      ],
    };
  },
  validations() {
    return {
      form: {
        url: {
          isValidUrl: (val) => isValidUrl(val),
          required,
        },
        apikey: {
          required: requiredIf(function (item) {
            return item.id != 3;
          }),
        },
        token: {
          required: requiredIf(function (item) {
            return item.id == 3;
          }),
        },
        apisecret: {
          required: requiredIf(function (item) {
            return item.is_zort && item.id != 3;
          }),
        },
        username: {
          required: requiredIf(function (item) {
            return !item.is_zort && item.id != 3;
          }),
        },
        password: {
          required: requiredIf(function (item) {
            return !item.is_zort && item.id != 3;
          }),
        },
        chanel_id: {
          required: requiredIf(function (item) {
            return !item.is_zort && item.id != 3;
          }),
        },
        brand_code: {
          required: requiredIf(function (item) {
            return !item.is_zort;
          }),
        },
        mock_email: {
          required: requiredIf(function (item) {
            return !item.is_zort && item.id != 3;
          }),
          email,
        },
        mock_birthday: {
          required: requiredIf(function (item) {
            return !item.is_zort && item.id != 3;
          }),
        },
        storename: {
          required: requiredIf(function (item) {
            return item.is_zort && item.id != 3;
          }),
        },
        // order_status: {
        //   required: requiredIf(function (item) {
        //     return item.is_zort;
        //   }),
        // },
        // order_paymentstatus: {
        //   required: requiredIf(function (item) {
        //     return item.is_zort;
        //   }),
        // },
        name: {
          required: requiredIf(function (item) {
            return item.is_zort;
          }),
        },
      },
    };
  },
  created() {
    this.getData();
    // this.getDataById();
  },
  methods: {
    clearForm() {
      this.$v.form.$reset();
      this.form = {
        id: 0,
        name: "",
        image_url: "",
        description: "",
        status: "",
        url: "",
        username: "",
        password: "",
        brand_code: "",
        chanel_id: "",
        mock_email: "",
        mock_birthday: "",
        key: "",
        token: "",
      };
    },
    openModal(form) {
      if (form.id == 2 || form.name.toLowerCase() == "zort") {
        this.getZortDetail();
      } else {
        this.form = { ...form };
      }
      this.modalShow = true;
    },
    async getZortDetail() {
      const res = await this.axios(`/plugin/pluginType/2`);

      // this.data = res.data.detail;
      this.form = res.data.detail;
      this.form.is_zort = true;
    },
    async getData() {
      const res = await this.axios(`/Plugin/GetList`);

      this.data = res.data.detail;
    },

    async updateData(check, list = this.form) {
      try {
        let payload = { ...list };
        payload.is_pdpa = payload.id == 1 ? 1 : 0;
        if (this.form.id != 3)
          payload.mock_birthday = this.$moment(payload.mock_birthday).format(
            "YYYY-MM-DD"
          );

        payload.chanel_id = payload.chanel_id || 0;

        this.$v.form.$touch();
        console.log(this.$v.form);
        if (check && this.$v.form.$error) return;

        this.$bus.$emit("showLoading");
        const res = await this.axios.put(`/Plugin/pluginType/save`, payload);

        this.$bus.$emit("hideLoading");
        if (res.data.result) {
          this.modalShow = false;
          this.successAlert();
          this.getData();
        } else {
          this.errorAlert(res.data.message);
        }
      } catch (error) {
        console.log(error);
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
  },
};
</script>

<style>
.image-logo {
  border-radius: 0.5rem;
  width: 75px;
  height: auto;
  aspect-ratio: 1;
}
.card-footer {
  padding: 0.75rem 1rem;
}
.setting-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.dropdown-menu {
  min-width: unset;
  width: max-content;
}
.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
</style>
